import React from "react"
import Layout from "../components/layout"
import Image  from "../components/image"
import ContactForm from "../components/contact"
import SEO from "../components/seo"
import {Container,Row,Col} from 'react-bootstrap'


const mobileDesktop =()=>{
    return(
    <Layout>
        <SEO title="Cloud Solution" />
        <div className="homeAuto">
            <Image alt="home automation" filename="cloud-solutions.png"  />
                <div className="cloudImage">
                    <h1 style={{fontWeight:"bold"}}  className="cldhead">Welcome to the Cloud.</h1>
                    <h3 className="cldheadpara">Our Cloud solutions deliver possibilities only limited by your imagination.</h3>
                </div>
        </div>
        <Container>
            <Row className="aboutrow">
                <Col sm={12} lg={6} className="manuPara1">
                    <p>Make your custom applications and tools ready for today’s users with cloud and web-based programming solutions. Whether it be a website, cloud application, or client application you can now attract the “on-the-go” user. Allow your users to securely access data while on the road or on a partner site. We can even mobilize your remote teams with a tablet application. Connect international conferences from any corner of the globe</p>
                    <p>Create an app to advertise in the app market. If you have a great idea for an app, Trace Width Technologies can fulfil your dream for a low, fixed-cost solution, allowing you to market and sell your app with complete authority over the functions, licensing, and distribution.</p>
                </Col>
                <Col sm={12} lg={6}>
                    <Image alt="software" filename="images-inside_26.png"  />
                </Col>
                
            </Row>

            <Row>
                <Col sm={12} lg={4}><Image alt="cloud" filename="cloud-b.png"  /></Col>
                <Col sm={12} lg={8}>
                    <h3 className="hardwareh3">Explore Infinite Possibilities with Trace Width Cloud Services</h3>
                    <p className="manuPara1">Trace Width Technology Solutions has the in-depth competence to discover cloud computing for you and help clients navigate through the cloud landscape, implement the most recommended cloud strategies for yielding fastest results, and provides full-stack cloud applications and infrastructure management services with a focus on client usability and customer service.</p>
                    <Row>
                        <Col sm={12} lg={6} className="crmCardCol">
                            <Image alt="cloud" filename="cloud-s1.png" style={{width : "100px"}}/>
                            <h3 className="cloudh3">Cloud Migration</h3>
                            <p className="cloudpara">Trace Width Cloud Computing Migration Services offer a safe, agile, and flawless application migration to the cloud using the first-class scientific know-how.</p>
                       
                        </Col>
                        <Col sm={12} lg={6} className="crmCardCol">
                            <Image alt="cloud" filename="cloud-s2.png" style={{width : "100px"}} />
                            <h3 className="cloudh3">Cloud Security</h3>
                            <p className="cloudpara">Our Cyber Cloud Security Services address the unique challenges of securing virtual environments for cloud and Infrastructure-as-a-Service.</p>
                       
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} lg={6} className="crmCardCol">
                            <Image alt="cloud" filename="cloud-s3.png" style={{width : "100px"}} />
                            <h3 className="cloudh3">Software Services</h3>
                            <p className="cloudpara">Application performance and user interface dictates your end user’s quality of experience.  Trace Width offers the best in class UI/UX as well as efficient coding standards.</p>
                       
                        </Col>
                        <Col sm={12} lg={6} className="crmCardCol">
                            <Image alt="cloud" filename="cloud-s2.png" style={{width : "100px"}} />
                            <h3 className="cloudh3">Software as a Service</h3>
                            <p className="cloudpara">Create an enterprise class software platform which can grow your business to new heights. Trace Width removes the complexity of development and secure IT environments for SaaS.</p>
                       
                        </Col>
                    </Row>
                </Col>
            </Row>
            <h3 className="cloudHeader">Trace Width Technology Solutions gives you the right cloud infrastructure, perfectly pooled resources, and world class technical support to run your business enterprise flawlessly.</h3>
        
            <Row className="aboutrow">
                <Col sm={12} lg={6} className="manuPara1">
                    <p>In simple terms, Trace Width cloud services enables you to control your cloud your way. If we had to define our cloud services in three words; scalable, dynamic, and secure represent Trace Width’s core competencies.</p>
                    <p>Cloud services allows you to start-up and run your resources smoothly per your business model.  Trace Width offers sophisticated algorithms such as proactive scaling which prevents your system from falling short of resources and save your cost.</p>
                </Col>
                <Col sm={12} lg={6}><Image alt="wear" filename="clud-l.png"  /></Col>
            </Row>
        </Container>

            
        <div className="headerForm"> 
            <br/>
            <div className="homeCardrow5">Let’s take to the Cloud and get your project started today!</div>
            <ContactForm  />
        </div>
          
    </Layout>
);

}

export default mobileDesktop;

